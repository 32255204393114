<template>
  <div class="mt-10">
    <div class="overflow-auto">
      <template v-if="isJenisSpkUnit">
        <table class="table-auto table-sticky border-collapse w-full">
          <!-----------TABLE HEAD------------>
          <thead class="font-bold">
            <tr class="d-theme-dark-bg">
              <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-center whitespace-no-wrap" colspan="2">#</th>
              <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-center">Nama</th>
              <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-center whitespace-no-wrap">Bobot</th>
              <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-center whitespace-no-wrap">Sharing</th>
              <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-center whitespace-no-wrap">Foto Progress</th>
              <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-center whitespace-no-wrap">File Progress</th>
              <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-center whitespace-no-wrap">Nilai Adjust</th>
              <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-center whitespace-no-wrap">Total</th>
            </tr>
          </thead>
          <!-----------TABLE BODY------------>
          <tbody v-for="(group, index) in itemsGroupedByUnit" :key="index">
            <tr class="hover-bg-primary-transparent-10">
              <td class="border border-solid d-theme-border-grey-light p-2 text-sm font-bold text-left" colspan="6">Unit: {{ group.nama_properti_tipe }} / {{ group.no_unit }}</td>
            </tr>
            <tr v-for="(item, i) in group.items" :key="i">
              <td class="border border-solid d-theme-border-grey-light p-1 text-sm font-medium text-left"></td>
              <td class="border border-solid d-theme-border-grey-light p-1 text-sm font-medium text-left">{{ index + 1 }}</td>
              <td class="border border-solid d-theme-border-grey-light p-1 text-sm font-medium text-left">{{ item.nama_item_progress_pekerjaan }}</td>
              <td class="border border-solid d-theme-border-grey-light p-1 text-sm font-medium text-right">{{ item.bobot }}%</td>
              <td class="border border-solid d-theme-border-grey-light p-1 text-sm font-medium text-center">{{ item.sharing ?  'Ya' : 'Tidak' }}</td>
              <td class="border border-solid d-theme-border-grey-light p-1 text-sm font-medium text-center">
                <span v-if="item.list_foto_kontraktor.length > 0" class="text-xs underline cursor-pointer" @click="showFiles(item.list_foto_kontraktor)">Lihat Foto</span>
                <span v-else class="text-sm">-</span>
              </td>
              <td class="border border-solid d-theme-border-grey-light p-1 text-sm font-medium text-center">
                <span v-if="item.list_file_kontraktor.length > 0" class="text-xs underline cursor-pointer" @click="showFiles(item.list_file_kontraktor)">Lihat Files</span>
                <span v-else class="text-sm">-</span>
              </td>
              <td class="border border-solid d-theme-border-grey-light p-2 text-sm font-medium text-right">{{ getNominalPenguranganPekerjaan(item.id) | idr }}</td>
              <td class="border border-solid d-theme-border-grey-light p-1 text-sm font-medium text-right">{{ (item.harga_satuan - getNominalPenguranganPekerjaan(item.id)) | idr }}</td>
            </tr>
          </tbody>
        </table>
      </template>

      <template v-if="isJenisSpkNonUnit">
        <table class="table-auto table-sticky border-collapse w-full">
          <!-----------TABLE HEAD------------>
          <thead class="font-bold">
            <tr class="d-theme-dark-bg">
              <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-center whitespace-no-wrap">#</th>
              <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-center">Nama</th>
              <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-center whitespace-no-wrap">Bobot</th>
              <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-center whitespace-no-wrap">Sharing</th>
              <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-center whitespace-no-wrap">Foto Progress</th>
              <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-center whitespace-no-wrap">File Progress</th>
            </tr>
          </thead>
          <!-----------TABLE BODY------------>
          <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <td class="border border-solid d-theme-border-grey-light p-2 text-sm font-medium text-left">{{ index + 1 }}</td>
              <td class="border border-solid d-theme-border-grey-light p-2 text-sm font-medium text-left">{{ item.nama_item_progress_pekerjaan }}</td>
              <td class="border border-solid d-theme-border-grey-light p-2 text-sm font-medium text-right">{{ item.bobot }}%</td>
              <td class="border border-solid d-theme-border-grey-light p-2 text-sm font-medium text-center">{{ item.sharing ?  'Ya' : 'Tidak' }}</td>
              <td class="border border-solid d-theme-border-grey-light p-1 text-sm font-medium text-center">
                <span v-if="item.list_foto_kontraktor.length > 0" class="text-xs underline cursor-pointer" @click="showFiles(item.list_foto_kontraktor)">Lihat Foto</span>
                <span v-else class="text-sm">-</span>
              </td>
              <td class="border border-solid d-theme-border-grey-light p-1 text-sm font-medium text-center">
                <span v-if="item.list_file_kontraktor.length > 0" class="text-xs underline cursor-pointer" @click="showFiles(item.list_file_kontraktor)">Lihat Files</span>
                <span v-else class="text-sm">-</span>
              </td>
            </tr>
          </tbody>
          <!-----------TABLE FOOT------------>
          <tfoot>
            <tr class="d-theme-dark-bg">
              <th class="border border-solid d-theme-border-grey-light p-2 whitespace-no-wrap text-sm font-bold text-right" colspan="3">{{ sumBy(items, 'bobot') }}%</th>
              <th class="border border-solid d-theme-border-grey-light p-2 whitespace-no-wrap text-sm font-bold text-right"></th>
              <th class="border border-solid d-theme-border-grey-light p-2 whitespace-no-wrap text-sm font-bold text-right"></th>
              <th class="border border-solid d-theme-border-grey-light p-2 whitespace-no-wrap text-sm font-bold text-right"></th>
            </tr>
          </tfoot>
        </table>
      </template>

      <template v-if="isJenisSpkOther">
        <table class="table-auto table-sticky border-collapse w-full">
          <!-----------TABLE HEAD------------>
          <thead class="font-bold">
            <tr class="d-theme-dark-bg">
              <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-center whitespace-no-wrap">#</th>
              <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-center">Nama</th>
              <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-center">Satuan</th>
              <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-center whitespace-no-wrap">Qty</th>
              <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-center whitespace-no-wrap">Harga</th>
              <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-center whitespace-no-wrap">Nilai Adjust</th>
              <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-center whitespace-no-wrap">Total</th>
            </tr>
          </thead>
          <!-----------TABLE BODY------------>
          <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <td class="border border-solid d-theme-border-grey-light p-2 text-sm font-medium text-left">{{ index + 1 }}</td>
              <td class="border border-solid d-theme-border-grey-light p-2 text-sm font-medium text-left">{{ item.nama_item_pengadaan }}</td>
              <td class="border border-solid d-theme-border-grey-light p-2 text-sm font-medium text-left">{{ item.satuan_item_pengadaan }}</td>
              <td class="border border-solid d-theme-border-grey-light p-2 text-sm font-medium text-right">{{ item.qty }}</td>
              <td class="border border-solid d-theme-border-grey-light p-2 text-sm font-medium text-right">{{ item.harga_satuan | idr }}</td>
              <td class="border border-solid d-theme-border-grey-light p-2 text-sm font-medium text-right">{{ getNominalPenguranganPekerjaan(item.id) | idr }}</td>
              <td class="border border-solid d-theme-border-grey-light p-2 text-sm font-medium text-right">{{ (item.harga_total - getNominalPenguranganPekerjaan(item.id)) | idr }}</td>
            </tr>
          </tbody>
          <!-----------TABLE FOOT------------>
          <tfoot>
            <tr class="d-theme-dark-bg">
              <th class="border border-solid d-theme-border-grey-light p-2 whitespace-no-wrap text-sm font-bold text-right" colspan="7">{{ (sumBy(items, 'harga_total') - totalNominalDikurangi) | idr }}</th>
            </tr>
          </tfoot>
        </table>
      </template>
    </div>

    <!--modals-->
    <FilesViewer :isActive.sync="modalFiles.active" :filesUrl="modalFiles.filesUrl"/>
  </div>
</template>

<script>
import FilesViewer from '@/views/components/files-viewer/FilesViewer'
import constant from '@/constant/constant'
import _ from 'lodash'

export default {
  name: 'TabPekerjaan',
  props: {
    isActive: { type: Boolean }
  },
  components: {
    FilesViewer
  },
  computed: {
    storeSpkShow () {
      return this.$store.state.proyek.spkShow
    },
    spkData () {
      return this.storeSpkShow.spkData
    },
    listAdjust () {
      return this.spkData.adjust
    },
    listAdjustPekerjaan () {
      return _.flatMap(this.listAdjust, item => item.details)
    },
    isJenisSpkUnit () {
      return this.spkData.header.jenis_spk === constant.JENIS_SPK.UNIT
    },
    isJenisSpkNonUnit () {
      return this.spkData.header.jenis_spk === constant.JENIS_SPK.NON_UNIT
    },
    isJenisSpkOther () {
      return this.spkData.header.jenis_spk !== constant.JENIS_SPK.NON_UNIT && this.spkData.header.jenis_spk !== constant.JENIS_SPK.UNIT
    },
    items () {
      return this.spkData.pekerjaan
    },
    itemsGroupedByUnit () {
      let itemsGrouped = _.map(_.uniqBy(this.spkData.pekerjaan, item => item.id_properti_unit), it => _.pick(it, ['id_properti_unit', 'no_unit', 'nama_properti_tipe', 'harga_total']))
      itemsGrouped = _.map(itemsGrouped, group => {
        group.items = _.filter(this.spkData.pekerjaan, it => it.id_properti_unit === group.id_properti_unit)
        return group
      })
      return itemsGrouped
    },
    totalNominalDikurangi () {
      return _.sumBy(this.listAdjustPekerjaan, item => item.nominal)
    }
  },
  data () {
    return {
      modalFiles: {
        filesUrl: [],
        active: false
      }
    }
  },
  methods: {
    getNominalPenguranganPekerjaan (idPekerjaan) {
      return _.sumBy(this.listAdjustPekerjaan, item => item.nominal)
    },

    sumBy (objects, key) {
      return _.sumBy(objects, key)
    },

    showFiles (urls) {
      this.modalFiles.filesUrl = urls
      this.modalFiles.active = true
    }
  }
}
</script>
